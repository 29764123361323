<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>

      <div class="player listmain type-s3s spk3 game-spk3 game-s3s-qt">
        <!-- 三军 -->
        <div code="*" class="data c*" v-for="(item,index) in rowsData" :key="item.title">
          <h3>{{item.title}}</h3>
          <ul>
            <li v-for="ele in item.arr" :key="ele.ResultID" :class="ele.selected ? 'selected' : ''" @click="setCurrentSelected(ele)">
              <ol class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">
                {{ ele.Odds }}</ol>
              <ol class="td_cash">
                <input type="numner" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money">
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li style="width:40px" v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
            <li v-if="index==5"></li>
          </ul>
        </div>
        <!--  end -->

        <!--  -->
      </div>
      <yushe />
      <!-- 表格 -->
      <div class="roadmap">
        <table class="thlottab">
          <tbody>
            <tr>
              <th v-for="item in ltTitleArr" @click="changeQiu(item.index)" :class="item.index==qiuIndex?'selected':''" :key="item.title">{{item.title}}</th>
            </tr>
          </tbody>
        </table>

        <!-- 总和单双 -->
        <table colspan="0" id="lt_sum_ds" cellspan="0" class="lttab">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span :class="fontColor[ele]">{{ele}}</span>

              </td>

            </tr>

          </tbody>
        </table>

      </div>
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  data() {
    return {
      groupnames:'sj',
      ltArr: [],
      ltTitleArr: [
        { title: "第一球", index: 1 },
        { title: "第二球", index: 2 },
        { title: "第三球", index: 3 },
        { title: "总和单双", index: 4 },
        { title: "总和大小", index: 5 },
      ],
      tableType: 1,
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "三军", arr: [] },
        { title: "三同号", arr: [] },
        { title: "二不同号", arr: [] },
        { title: "二同号复选", arr: [] },
        { title: "二同号单选", arr: [] },
        { title: "三不同号单选", arr: [] },
      ],
      labelArr1: [1, 2, 3, 4, 5, 6],
      labelArr2: [111, 222, 333, 444, 555, 666],
      labelArr3: [12, 13, 14, 15, 16, 23, 24, 25, 26, 34, 35, 36, 45, 46, 56],
      labelArr4: [11, 22, 33, 44, 55, 66],
      labelArr5: [
        112, 113, 114, 115, 116, 122, 133, 144, 155, 166, 223, 224, 225, 226,
        233, 244, 255, 266, 334, 335, 336, 344, 355, 366, 445, 446, 455, 466,
        556, 566,
      ],
      labelArr6: [
        123, 124, 125, 126, 134, 135, 136, 145, 146, 156, 234, 235, 236, 245,
        246, 256, 345, 346, 356, 456,
      ],
      lutu1: {
        arr1: [[], [], [], [], []],
      },

      lutu2: {
        arr1: [[], [], [], [], []], //单双
      },

      lutu3: {
        arr1: [[], [], [], [], []], //单
      },

      lutu4: {
        arr1: [[], [], [], [], []],
      },

      lutu5: {
        arr1: [[], [], [], [], []],
      },
    };
  },
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 三军
        let arr1 = this.filterData(resAArr, 853, 858, this.labelArr1);
        this.rowsData[0].arr = arr1;
        // 三同号
        let arr2 = this.filterData(resAArr, 863, 868, this.labelArr2);
        this.rowsData[1].arr = arr2;
        // 二不同号
        let arr3 = this.filterData(resAArr, 934, 948, this.labelArr3);
        this.rowsData[2].arr = arr3;
        // 二同号复选
        let arr4 = this.filterData(resAArr, 949, 954, this.labelArr4);
        this.rowsData[3].arr = arr4;
        // 二同号单选
        let arr5 = this.filterData(resAArr, 870, 899, this.labelArr5);
        this.rowsData[4].arr = arr5;
        // 三不同号单选
        let arr6 = this.filterData(resAArr, 900, 919, this.labelArr6);
        this.rowsData[5].arr = arr6;

        this.$forceUpdate();
      },
    },
  },
  created() {},

  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []],
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []],
      };

      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            let arr1 = [[]],
              arr2 = [[]],
              arr3 = [[]],
              arr4 = [[]],
              arr5 = [[]];

            // --------------
            let list = resObj.data;
            list.reverse()
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");

              let q1 = element.codeArr[0];
              let q2 = element.codeArr[1];
              let q3 = element.codeArr[2];
              let sum = 0;
              element.codeArr.forEach((num) => {
                sum += Number(num);
              });
              // 第一球 单双-----------------------
              let ds1 = q1 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr1, ds1);
              // 第二球 单双
              let ds2 = q2 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr2, ds2);
              // 第三球 单双
              let ds3 = q3 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr3, ds3);
              // 总和单双
              let zhds = sum % 2 == 0 ? "双" : "单";
              this.initlutuData(arr4, zhds);
              // 总和大小
              let zhdx = sum >= 11 ? "大" : "小";
              this.initlutuData(arr5, zhdx);
              // ----------------------------------------------------------
            });
            //龙虎
            this.buquanArr(arr1, this.lutu1.arr1);
            this.buquanArr(arr2, this.lutu2.arr1);
            this.buquanArr(arr3, this.lutu3.arr1);
            this.buquanArr(arr4, this.lutu4.arr1);
            this.buquanArr(arr5, this.lutu5.arr1);

            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          } else {
            this.ltArr = [];
          }
        } else {
          this.ltArr = [];
        }
      });
    },
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain .data ul li {
  width: 33.33%;
}
</style>